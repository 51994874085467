<template>
  <div id="webset">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="用户管理" name="first">用户管理</el-tab-pane>
      <el-tab-pane label="配置管理" name="second">配置管理</el-tab-pane>
      <el-tab-pane label="角色管理" name="third">角色管理</el-tab-pane>
      <el-tab-pane label="定时任务补偿" name="fourth">定时任务补偿</el-tab-pane>
    </el-tabs>

    <CommonForm :form="openForm" :formLabel="openFormLabel" inline></CommonForm>

  </div>
</template>

<script>
import CommonForm from 'components/CommonForm.vue'

export default {

  components: {

    CommonForm
  },
  data () {
    return {
      activeName: 'first',

      openForm: {
        name: '',
        role: '',
        phoneL: '',
        address: ''
      },
      openFormLabel: [
        {
          model: 'webname',
          label: '网站名称'
        },
        {
          model: 'sms',
          label: '域名'
        },
        {
          model: 'phone',
          label: '客服电话'
        },
        {
          model: 'sms',
          label: '短信通知'
        }

      ]
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
